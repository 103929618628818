* {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        .vjs-modal-dialog .vjs-modal-dialog-content {
            background-color: #3a5a78 !important;
        }

        .videobox {
            height: calc(100vh - 150px);
            display: flex;
            overflow: auto;
        }
          .disclaimer-style {
            padding: 0 8px;
            font-size: 17px;
        }
          .disclaimer-div{
              text-align: center;
              /*margin-top: 3vh;*/
               color: #fff;
          }
        
        /* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
/* Styles */
 

            .disclaimer-style {
                font-size: 12px;
            }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) and (max-width:767px) and (orientation:landscape) {
/* Styles */
[class="survey-que"] {
                font-size: 2vw !important;
            }

            .videobox {
                flex-direction: row;
                height:calc(100vh - 150px);
            }
            .transcript-body {
      height: 100px;
      width:300px;
      
    }
            .disclaimer-div{
              text-align: center;
              margin-top: 3vh;
               color: #fff;
          }
            .disclaimer-style {
                font-size: 12px;
            }
        

}

/* Smartphones (portrait) ----------- */
@media only screen and (min-width : 320px) and (max-width:480px) {
/* Styles */
[class="survey-que"] {
                font-size: 4vw !important;
            }

            .videobox {
                flex-direction: column;
            }
            .transcript-body {
      height: 200px;
      
    }
            .disclaimer-div{
              text-align: center;
              /*margin-top: 3vh;*/
               color: #fff;
          }
            .disclaimer-style {
                font-size: 12px;
            }
        
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
/* Styles */
[class="survey-que"] {
                font-size: 2vw !important;
            }

            .videobox {
                flex-direction: row;
                height:calc(100vh - 150px);
            }
            /*.transcript-body {
      height: 100px;
      width:300px;
      
    }*/
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
/* Styles */
[class="survey-que"] {
                font-size: 4vw !important;
            }

            .videobox {
                flex-direction: column;
            }
            .transcript-body {
      height: 400px;
      
    }
             .disclaimer-style {
                font-size: 17px;
            }
}

/* Portrait 
 Declare the same value for min- and max-width to avoid colliding with desktops 
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

 Landscape 
 Declare the same value for min- and max-width to avoid colliding with desktops 
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    

}
 Portrait 
 Declare the same value for min- and max-width to avoid colliding with desktops 
 Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

}*/

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    [class="survey-que"] {
                font-size: 2vw !important;
            }

            .videobox {
                flex-direction: row;

            }
            /*.transcript-body {
      height: 100px;
      width:300px;
      
    }*/

}

/**********
iPad 3
**********/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}
/* Desktops and laptops ----------- */
@media only screen  and (min-width : 1224px) {
/* Styles */
}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
/* Styles */

}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
.disclaimer-style {
                font-size: 10px;
            }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
.disclaimer-style {
                font-size: 10px;
            }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
/* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
/* Styles */
}

