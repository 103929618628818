@import "./partials/variables.scss";

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    print-color-adjust: exact !important;
  }

  @page {
    size: auto;
    margin: 0;
  }
  #kampyleButtonContainer {
    display: none;
  }
}

.ng-mydp .mydpicon:before {
  font-family: mydatepicker !important;
}

//  common style for button loading indicator alignment and spacing
.spinner-styling {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: toEm(8);
}

button > i.fa.fa-spinner.fa-spin {
  font-size: toEm(24);
}

// Start of MyDatePicker Styles
.ng-mydp * {
  font-family: $gotham-regular !important;
}

.ng-mydp {
  $date-picker-warning: #be5917;
  $date-picker-danger: #822b2e;
  $date-picker-success: #358208;

  border: none !important;

  .myDpSelector {
    border: 0;
  }

  th.myDpWeekDayTitle {
    background-color: white;
    font-weight: bold;
    color: #484848;
    padding-bottom: toEm(15);
  }

  .myDpSelector:focus {
    box-shadow: none;
  }

  .myDpNextMonth,
  .myDpPrevMonth {
    visibility: hidden;
  }

  td.myDpDaycell {
    padding-top: toEm(12) !important;
    padding-bottom: toEm(12) !important;

    &:disabled {
      background-color: transparent;
    }
  }

  .myDpMonthYearSelBar {
    display: flex;
    height: 42px;
    background-color: white !important;

    > div {
      display: flex;
      align-items: center;
    }

    .myDpMonthYearText {
      justify-content: center;

      .myDpHeaderBtn {
        font-size: 18px;
        font-weight: bold;
        color: #484848;

        &:focus {
          color: #484848;
        }
      }
    }

    .pagination-button {
      background-size: 22px;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;

      &::before {
        content: "";
      }

      &:disabled {
        visibility: hidden;
      }

      &:focus {
        box-shadow: inset 0 0 0 1px #000;
      }
    }

    .myDpNextBtn button {
      @extend .pagination-button;
      background-image: url("/assets/images/icons/calendar-icon-right.svg");
    }

    .myDpPrevBtn button {
      @extend .pagination-button;
      background-image: url("/assets/images/icons/calendar-icon-left.svg");
    }
  }

  .myDpDaycell:focus {
    box-shadow: inset 0 0 0 1px #000;
  }

  .myDpDaycell span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    margin: auto;
    color: #757575;
  }

  .myDpDaycell.warning span {
    background: $date-picker-warning;
    color: white;
  }

  .myDpDaycell.myDpSelectedDay.warning span {
    background: $date-picker-warning;
    border: 3px solid white;
  }

  .myDpDaycell.danger span {
    background: $date-picker-danger;
    color: white;
  }

  .myDpDaycell.myDpSelectedDay.danger span {
    background: $date-picker-danger;
    border: 3px solid white;
  }

  .myDpDaycell.success span {
    background: $date-picker-success;
    color: white;
  }

  .myDpDaycell.myDpSelectedDay.success span {
    background: $date-picker-success;
    border: 3px solid white;
  }

  .myDpTableSingleDay,
  .myDpTableSingleDay.myDpSelectedDay,
  .myDpTableSingleDay:hover {
    background-color: white !important;
  }

  span.myDpMarkCurrDay,
  .myDpDaycell.myDpSelectedDay span {
    outline: 2px solid #3a5a78;
    background: white;
    border: 0;
  }
}
// End of MyDatePicker Styles

.headerbtndisabled {
  visibility: hidden;
}
#form-calendar .headermonthtxt {
  width: 185px;
  max-width: 185px;
  font-weight: bold;
}
.pointer-none {
  pointer-events: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #history-datepicker .ng-mydp td.daycell.myDpCurrMonth .markdate {
    top: 9px;
  }
  #history-datepicker .highlight-marked-selected {
    top: 9%;
  }
}
@media (min-width: $bootstrap-tablet) {
  #history-datepicker .ng-mydp .headermonthtxt {
    width: 440px;
  }
}

/* TEMP STYLING */
.navbar-header {
  float: none;
  text-align: center;
  color: $east-bay;
}

.container-fluid {
  padding: 0;
}

/* Default margins for the row
are causing complications */
.row {
  margin: 0;
}

.row.marginBottom0 {
  margin: 0 !important;
}

/* For the footer
The subtracted part is the footer + header heights*/
.app-container {
  min-height: calc(100vh - #{toEm(56)});
}

.spacer-thin {
  background-color: $hartford-gray;
  height: toEm(2);
  width: 100%;
}

.spacer-two {
  height: toEm(10);
  width: 100%;
  background-color: $hartford-light-blue;
}

body {
  font-size: 16px;
  font-family: $gotham-regular;
  overflow-x: hidden;
}

// for bold and strong font is gotham medium.
b,
strong {
  font-family: $gotham-medium;
}

body.no-scroll {
  overflow: hidden;
}

/* Useful Style to remove padding in whatever line you are using.
Suggested with any col-* as they can add padding you may not want.*/
.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-horizontal-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.button-col {
  text-align: center;
}

.terms-condition-style {
  color: $blue-link-mod;
}

.display-inline {
  display: inline-block;
}

.float-right {
  float: right;
}

.GothamSSm-Book,
.GothamSSm-book {
  font-family: $gotham-regular;
  font-weight: 400;
  font-style: normal;
}

.GothamSSm-Medium,
.GothamSSm-medium {
  font-family: $gotham-medium;
  font-weight: 600;
  font-style: normal;
}

.GothamSSm-Bold,
.GothamSSm-bold {
  font-family: $gotham-bold;
  font-weight: 700;
  font-style: normal;
}

/** Italicized Versions Below **/

.GothamSSm-Book-Italic,
.GothamSSm-book-italic {
  font-family: $gotham-regular;
  font-weight: 400;
  font-style: italic;
}

.GothamSSm-Medium-Italic,
.GothamSSm-medium-italic {
  font-family: $gotham-medium;
  font-weight: 400;
  font-style: italic;
}

.GothamSSm-Bold-Italic,
.GothamSSm-bold-italic {
  font-family: $gotham-bold;
  font-weight: 700;
  font-style: italic;
}

.icon-svg {
  height: toREm(51);
  width: toREm(50);
  margin-right: 10px;
}
.icon-header {
  height: toREm(51);
  width: toREm(50);
  margin-right: 10px;
}
.blueBorder {
  background: $light-gray;
  border-bottom: toEm(7.2) solid $hartford-light-blue;
  margin: 0;
}

/* Icons styling */
@media (max-width: $bootstrap-mobile) {
  .selector table.header td > div {
    width: 92% !important;
    margin: 0 auto;
  }
}

@media (max-width: $bootstrap-ipad-pro) {
  select {
    border-radius: toEm(0);
  }
}

@media (max-width: $bootstrap-tablet) {
  .icon-svg {
    height: toREm(56);
    width: toREm(68);
  }
  .icon-error {
    height: toEm(25);
    margin-top: toEm(-2);
  }
  .selector table.header td > div {
    width: 90%;
    margin: 0 auto;
  }
  #history-datepicker .ng-mydp .headermonthtxt {
    width: 100%;
  }
}
//@media (max-width: $xs-mobile) {
//  #history-datepicker .ng-mydp .headermonthtxt {
//    width: toEm(260);
//  }
//}
@media (min-width: $bootstrap-medium-desktop) and (max-width: $bootstrap-xl-desktop) {
  .selector table.header td > div {
    width: 92%;
    margin: 0 auto;
  }
  //#history-datepicker .ng-mydp .headermonthtxt {
  //  width: 100%;
  //}
}
@media (min-width: $bootstrap-tablet) {
  .icon-svg {
    height: toREm(68);
    width: toREm(68);
  }
}

@media (min-width: $bootstrap-medium-desktop) {
  .icon-svg {
    height: toREm(90);
    width: toREm(90);
  }
}
@media (min-width: $bootstrap-xl-desktop) {
  #history-datepicker .ng-mydp .headermonthtxt {
    width: 404px;
  }
}
@media (min-width: $desktop-width-maximum) {
  .full-page-stretch {
    margin-left: calc(-50vw + 50%) !important;
    margin-right: calc(-50vw + 51%) !important;
  }

  .max-desktop-width {
    max-width: $desktop-width-maximum !important;
    margin: 0 auto;
  }
}
@media (min-width: $desktop-large-width) {
  .max-desktop-width {
    max-width: $desktop-width-maximum !important;
    margin: 0 auto;
  }
}

/* Common CSS class below to use if the clickable area exceeds out of the outer anchor tag

  Purpose : -When we use secondary link and tertiary link components and nest it within a parent element, the clickable link area of the anchor
             increases to cover the width of the parent element
             See DE1015 for the issue
  Apply these classes to the parent div containing secondary link and tertiary link components
*/

.reduceClickableArea {
  display: flex;
}

/* Skeleton Loading Concept

  Pre-loading a content area with a flashing container while
  data is coming through a service call.
  This will help to reduce some of the page jumping
*/
.skeleton-container:after {
  content: "";
  background-repeat: no-repeat;
  animation-name: backgroundToggle;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background-color: $hartford-gray-45;
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.skeleton-container.removed:after {
  opacity: 0;
  visibility: hidden;
  z-index: -1 !important;
}

@keyframes backgroundToggle {
  from {
    background-color: $hartford-gray-45;
  }
  to {
    background-color: $hartford-gray-20;
  }
}

/* Claim Wizard specific requirement for regular primary input appearance. */
input#policy-number {
  margin-top: toEm(5);
}

input::-ms-clear {
  display: none;
}

//Medallia fix attempt
#formLightboxContainer + #kampyleButtonContainer button#nebula_div_btn {
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
}

.grouped-benefits {
  border-top: toEm(5) solid $hartford-lightblue;
  //-webkit-box-shadow: toEm(0) toEm(4) toEm(15) toEm(0) $hartford-box-shadow;
  box-shadow: toEm(0) toEm(4) toEm(15) toEm(0) $haartford-light-border;
  border-bottom-left-radius: toEm(4);
  border-bottom-right-radius: toEm(4);
  margin-bottom: toEm(30);
}

.number-wrap {
  white-space: nowrap;
  color: $blue-link-mod;
}
/*.intermittent-time-off i.fa.fa-angle-down{
  position: absolute;
  right: 0;
  background:transparent !important;
  text-align:center;
  width:toEm(20);
  top:0;
  line-height:toEm(29);
  font-size: toEm(24);
  pointer-events:none;
  color:$hartford-blue !important;
}*/
.intermittent-time-off-error select,
option {
  border: 1px solid $error-red !important;
}
.intermittent-time-off-error i.fa.fa-angle-down {
  position: absolute;
  right: 0;
  background: $error-red !important;
  text-align: center;
  width: toEm(20);
  top: 0;
  line-height: toEm(29);
  font-size: toEm(24);
  pointer-events: none;
  color: white;
}
button#reportAnAbsenceId {
  width: toEm(312);
}
#ques-input-margin > .tertiary-container-label {
  margin-bottom: toEm(10);
}

.leave-submit-message > div > a {
  color: $blue-link-mod;
}
#prefillModal .sliding-modal-window,
#sureModal .sliding-modal-window {
  z-index: 10;
}

.flex {
  display: flex;
  flex: 1;
}
.flexCol {
  flex-flow: column;
}
.flexRow {
  flex-flow: row;
}
.formWidth {
  max-width: toEm(600);
  margin: 0 auto;
}

.cancel-modal {
  color: $hartford-gray;
  font-size: toEm(14);
}

.error-icon-alignment {
  position: relative;
  left: toREm(-3);
}

.grey-text .checkbox-container img.icon {
  border-color: $select-gray;
}

@media (min-width: $bootstrap-min-med-desktop) {
  .error-icon-alignment {
    position: relative;
    left: toREm(-5);
  }
}

@media (max-width: 350px) {
  .missingInfoData .uploadBtn .default-width {
    white-space: normal;
    min-width: 10.1875rem;
  }
}

// add border to datepicker icon when its disabled
.ng-mydp .selectiongroup .selbtngroup button.btnpicker.btnpickerdisabled {
  border-radius: 0;
  border: 1px solid $hartford-blue;
}
// add border to datepicker icon when its disabled
//Intake healthcare summary component
.permission-container .secondary-link-container .GothamSSm-Bold {
  font-size: 16px !important;
}
.continuityQuesPop > div.popover-window-fp {
  padding-top: 10px !important;
  border-top: 5px solid $hartford-light-blue !important;
  border-bottom: none !important;
}
.continuityQuesPop > div.popover-window-fp > div.popover-container > div.popover-content-container {
  padding: 5px !important;
}
.continuityQuesPop
  > div.popover-window-fp
  > div.popover-container
  > div.popover-title-bar
  > img.popover-close-fp {
  margin-top: 1.5% !important;
}

@media (max-width: 320px) {
  .continuityQuesPop > div.popover-window-fp {
    margin-top: -36em !important;
  }
}
.cal-image-width > div.mobile-align > span > object {
  margin-top: -5px;
}
@media (min-width: 576px) {
  .cal-image-width > div.mobile-align > span > object {
    margin-top: -8px;
  }
  .cal-image-width > div.desktop-announcement {
    padding-left: 15px;
  }
}
.para-list > div > ul {
  margin-left: 37px;
}

.intake-sliding-model .modal-title-bar {
  border-bottom: none !important;
}

.button-size > button.btn-primary {
  font-size: 16px;
}

.button-size button.btn-primary:active {
  font-size: 16px;
}

.title-font-size > div > div > div > div.modal-title {
  width: auto;
}

.title-font-size > div > div > div > div > secondary-page-label > div.larger-label {
  font-size: 24px;
}

.radio-tile-margin > radio-tile > div > div.radio-tile-container {
  margin-bottom: 16px;
}

.multi-claim-modal .sliding-modal-window.active {
  height: 130% !important;
  overflow-y: auto;
  .secondary-container-label {
    font-size: 22px;
  }
  .sliding-modal-container img {
    width: 16.8px;
    height: 16.8px;
  }
  .sliding-modal-container .modal-content-container {
    max-height: 150vh;
    overflow: hidden;
    .GothamSSm-book {
      font-weight: 300;
    }
  }
  .sliding-modal-shader {
    height: 135vh;
  }
}

@media (max-width: 375px) {
  .multi-claim-modal .sliding-modal-window.active {
    .sliding-modal-shader {
      height: 180vh;
    }
  }
}
@media (max-width: 320px) {
  .multi-claim-modal .sliding-modal-window.active {
    .sliding-modal-shader {
      height: 210vh;
    }
  }
}

.image-padding > div > span > object.imgWidth {
  padding-top: 10px;
}

.image-padding > div > div.sub-container-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  margin-top: -4px;
}

.calendar-link-padding > tertiary-page-label > div > span {
  padding-left: 32px;
}

.calendar-link-padding > tertiary-page-label > div {
  padding-top: 6px;
}

.radio-font-size > huk-radio {
  font-size: 16px;
}

@media (max-width: 912px) {
  .image-padding > div.mobile-align {
    margin-left: 9px;
  }
}

.total-hour-text > span > tertiary-page-label > div > span {
  font-weight: bold;
}

.announcement-padding .tertiary-container-label #contactNumberId {
  color: $blue-link;
}

.mobile-font-size
  > app-huk-hour-min-unit-multi-dropdown
  > form
  > div
  > div
  > div
  > div
  > div
  > tertiary-page-label
  > div
  > span.regular-label {
  font-size: 16px;
}

.mobile-font-size
  > app-huk-hour-min-unit-multi-dropdown
  > form
  > div
  > div
  > div
  > div
  > tertiary-page-label
  > div
  > span.regular-label {
  font-size: 16px;
}

.mobile-font-size
  > app-huk-hour-min-unit-multi-dropdown
  > form
  > div
  > div
  > div
  > tertiary-page-label
  > div
  > span.regular-label {
  font-size: 16px;
}

.label-font-size
  > div
  > primary-radio
  > div
  > div
  > tertiary-page-label
  > div
  > span.smaller-label {
  font-size: 16px;
}

// Intake Forms
app-intake-step-layout form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  // UX Designers want 8px separation, HUK by default
  // uses 10px top for a huk-radio-button, so we need
  // to set the margin-bottom of the parent (the label) to -2px.
  // 10px - 2px = 8px separation.
  div:has(> tertiary-page-label > huk-radio-button) {
    display: flex;
    flex-direction: column;

    tertiary-page-label {
      margin-bottom: -2px;
    }
  }

  div:has(huk-popover) {
    flex-direction: row;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: -15px;
    padding-bottom: 15px;
  }

  .remove-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    color: #1570be;
    background: none;
    border: 0;

    &:hover span {
      text-decoration: underline;
      color: #23527c;
    }
  }
}

.answer > div > a {
  text-decoration: underline !important;
}
