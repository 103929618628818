@use "sass:math";
//Hartford Colors
$hartford-silver-grey: #d9d9d9;
$hartford-white: #ffffff;
$hartford-black: #000000;
$hartford-dark-gray: #808080;
$hartford-gray-text: #585858;
$hartford-blue: #3a5a78;
$hartford-label-blue: #167cc4;
$hartford-blue-70: #6e7c95;
$hartford-light-blue: #b6d3e9;
$hartford-link-blue: #167cc4;
$blue-intake: #2075c4;
$hartford-button-blue: #3c617d;
$hartford-light-blue-45: #deecf7;
$hartford-light-blue-20: #f0f6fb;
$hartford-red: #6e292d;
$hartford-gray: #484848;
$hartford-gray-80: #6b6b6b;
$hartford-gray-70: #726e6e;
$hartford-gray-45: #9a9695;
$hartford-gray-20: #cac8c8;
$hartford-gray-10: #cccccc;
$hartford-silver: #c0c0c0;
$hartford-bluish-green: #eaf4f0;
$hartford-gray-silver: #aaaaaa;
$hartford-gray-light: #dadada;
$hartford-gray-light-20: #f7f7f7;
$hartford-gray-input: #4f4f4f;
$blue-link-mod: #206bac;
$light-gray: #f6f6f9;
$of-white: #fbfbfd;
$cta-green: #018852;
$timeline-green: #10a318;
$timeline-dark-green: #15702e;
$very-light-gray: #f9f9f9;
$flag-gray: #f2f2f2;
$action-red: #d0021b;
$action-light-red: #ff0000;
$hart-ab-orange: #f26722;
$green-halo: #10a318;
$measure-magenta: #bd10e0;
$hartford-bright-ab: #009cd8;
$red-halo: #ff5252;
$hart-ab-orange-14: #f26722;
$hart-focus-blue: #2477bf;
$error-red: #b83b2f;
$hartford-focus-blue: #2d4765;
$hartford-orange: #f7941f;
$hartford-warning-text: #af5307;
$hartford-linen: #fdf3eb;
$hartford-light-grey: #a0a0a0;
$hartford-cornflower-blue: #6495ed;
$horizontal-line: #979797;
$hartford-lightblue: #cfe3f2;
$hartford-title-blue: #42678a;
$hartford-line-blue: #1d75c2;
$hartford-background-blue: #eaf2f8;
$hartford-light-gray: #d3d3d3;
$hartford-description-font: #3d3d3d;
$hartford-box-shadow: #9a9a9a;
$hartford-tile-border: #cfe3f2;
$hartford-lighter-gray: #dedede;
$hartford-accordion-gray: #ededed;
$hartford-status-gray: #52575c;
$hartford-timeline-gray: #8a8888;
$hartford-blur-gray: #9b9b9b;
$hartford-shadow-gray: #000006;
$hartford-separator-gray: #d9d9d9;
$hartford-card-border: #5396b5;
$hartford-peach: #fdf3e8;
//Unknown colors?
$hartford-hover: #4e7495;
//Unknown colors?
$light-green: #93bfae;
$medium-gray: #818181;
$hartford-light-pink: #f9edec;
$haartford-light-border: rgba(154, 154, 154, 0.5);
$hartford-white-full-opacity: rgba(255, 255, 255, 0);
$hartford-white-half-opacity: rgba(255, 255, 255, 0.5);
$hartford-white-zero-opacity: rgba(255, 255, 255, 1);
$huk-gray-disabled: #757575;
$huk-green: #118654;
$link-blue: #236197;
$title-black: #1a1a1a;
$hartford-danger-alert: #ffeeec;

//Old colors to remove
$white: #ffffff;
$mercury: #e5e5e5;
$gull-gray: #9eafbc;
$tropical-rain-forest: #00814b;
$east-bay: #3a5a78;
$light-grey: #a0a0a0;
$sky-grey: #c0c0c0;
$ocean-grey: #eee;
$select-gray: #555;
$hartford-calander-circle: #3c5b77;
$hartford-calendar-font: #888888;
$hartford-light-gray-border: #dadada;
$hartford-border-blue: #3973aa;
$message-bg-info: #eaf2f9;
$blue: #3a5a78;
$blue-active: #4e79a2;
$psv-link-blue: #236197;
$spinner-color-white: #f3f3f3;
$spinner-color-blue: #3498db;
$blue-link: #1570be;
//Fonts
$gotham-regular: HCo Gotham;
$gotham-bold: HCo Gotham SSm;

$gotham-medium: HCo Gotham SSm;

$GothamSSm-Bold: GothamSSm-Bold;

$GothamSSm-Bold: GothamSSm-Bold;

$gotham-regular-book: GothamSSm-Book;
$gotham-regular-bold: GothamSSm-Bold;
$glyphicons: "Glyphicons Halflings";

//Screen Breakpoints
$bootstrap-large-desktop: 1200px;
$bootstrap-medium-desktop: 992px;
$bootstrap-tablet: 768px;
$bootstrap-max-tablet: 776px;
$bootstrap-medium-device: 990px;
$xs-mobile: 400px;
$bootstrap-mobile: 767px;
$min-bootstrap-mobile: 707px;
$bootstrap-xl-desktop: 1365px;
$iphone-x: 320px;
$desktop-width-maximum: 1030px;
$desktop-large-width: 1440px;
$desktop-width: 1015px; //to review
$desktop-min: 830px; //to review
$desktop-break: 400px; //to review
$desktop-medium-break: 650px; //to review
$bootstrap-ipad-pro: 1366px;
$galaxy-fold: 414px;

//extra screen breakpoints
$bootstrap-min-med-desktop: 769px;
$bootstrap-min-large-desktop: 1231px;
$bootstrap-iphone-x: 321px;
$bootstrap-small-phone: 360px;
$bootstrap-fold-mobile: 280px;
//Common Bootstrap col measurements
$col-sm-12-width: 83.33%;
$col-sm-12-offset-1: 8.33%;

//Positioning
// 1em = 16px (or what we want it to be)
$basePx: 16; //Base pixel for conversion
@function toEm($pixel) {
  @return math.div($pixel, $basePx) + em;
}

@function toREm($pixel) {
  @return math.div($pixel, $basePx) + rem;
}

@function sub($a, $b) {
  @return ($a - $b) + em;
}

html {
  font-size: $basePx + px;
}

.modal-title-bar {
  border-bottom: 2px solid $hartford-gray-20;
  padding: 0;
}

.modal-title {
  width: 85%;
  display: inline-block;
}

.modal-close {
  float: right;
  cursor: pointer;
  margin-top: 2.5%;
  height: toEm(14);
}
